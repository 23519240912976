let  mainJS = require('./main.js');

function initTimelineSlider(){
    if( $('.swiper-timeline').length ){
        var swiperTimeline = new Swiper(".swiper-timeline", {
            slidesPerView: 2.1,
            spaceBetween: 200,
            initialSlide : 0,
            speed: 1000,
            touchRatio:0.1,
            resistanceRatio:0.1,
            slidesOffsetBefore:true,
            loop: true,
            centeredSlides: true,
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            on: {
                slidePrevTransitionStart: function () {
                    var activeSlide  = $('.swiper-timeline .swiper-slide').eq( this.activeIndex );
                    var nextSlide  = $('.swiper-timeline .swiper-slide').eq( this.activeIndex + 1 );
                    var item =  $('img', activeSlide),
                        nextImg =  $('img', nextSlide),
                        year =  $('.year', activeSlide),
                        pYear =  $('.year', nextSlide),
                        desc =  $('.description', activeSlide),
                        prevDesc =  $('.description', nextSlide);

        
                    TweenMax.to(pYear, 3, {ease: Power4.easeOut,startAt: {skewX:0},skewX:-100, transformOrigin:"50% 50%" });
                    TweenMax.to(pYear, 3, {ease: Power4.easeOut,startAt: {y:0},opacity:1,y:-100});
                    TweenMax.to(pYear, 1, { ease: Power4.easeOut, startAt: { scaleY: 0} , scaleY: 1});

                    TweenMax.to(year, 4, {ease: Power4.easeOut,startAt: {skewX:-100},skewX:0, transformOrigin:"50% 50%" });
                    TweenMax.to(year, 4, {ease: Power4.easeOut,startAt: {y:-100},opacity:1,y:0});
                    TweenMax.to(year, 2, { ease: Power4.easeOut, startAt: { scaleY: 0} , scaleY: 1});



                    TweenMax.to(desc, 2, {ease: Power4.easeOut,startAt: {opacity:0,y:50 },y:0,opacity:1});
                    TweenMax.to(prevDesc, 0, {ease: Power4.easeOut,startAt: {opacity:1,y:0 },y:-200,opacity:0});
                    TweenMax.to(item, 1.4, {ease: Power4.easeOut,startAt: {scale:1.3},scale:1,});
                    TweenMax.to(nextImg, 1.4, {ease: Power4.easeOut,startAt: {scale:1},scale:1.3,});
                },
                slideNextTransitionStart: function () {
                    var activeSlide  = $('.swiper-timeline .swiper-slide').eq( this.activeIndex );
                    var prevSlide  = $('.swiper-timeline .swiper-slide').eq( this.activeIndex - 1 );
                    var item =  $('img', activeSlide),
                        prevImg =  $('img', prevSlide),
                        year =  $('.year', activeSlide),
                        pYear =  $('.year', prevSlide),
                        desc =  $('.description', activeSlide),
                        prevDesc =  $('.description', prevSlide);
                
                        TweenMax.to(pYear, 4, {ease: Power4.easeOut,startAt: {skewX:0},skewX:100, transformOrigin:"50% 50%" });
                        TweenMax.to(pYear, 4, {ease: Power4.easeOut,startAt: {y:0},opacity:0,y:-100});
                        TweenMax.to(pYear, 2, { ease: Power4.easeOut, startAt: { scaleY: 1} , scaleY: 0});

                        TweenMax.to(year, 3, {ease: Power4.easeOut,startAt: {skewX:-100},skewX:0, transformOrigin:"50% 50%" });
                        TweenMax.to(year, 3, {ease: Power4.easeOut,startAt: {y:100},opacity:1,y:0});
                        TweenMax.to(year, 2, { ease: Power4.easeOut, startAt: { scaleY: 0} , scaleY: 1});

                        TweenMax.to(desc, 2, {ease: Power4.easeOut,startAt: {opacity:0,y:50 },y:0,opacity:1});
                        TweenMax.to(prevDesc, 0, {ease: Power4.easeOut,startAt: {opacity:1,y:0 },y:-200,opacity:0});
                        TweenMax.to(item, 1.4, {ease: Power4.easeOut,startAt: {scale:1.3},scale:1,});
                        TweenMax.to(prevImg, 1.4, {ease: Power4.easeOut,startAt: {scale:1},scale:1.3,});
                },
            },
            breakpoints: {
                200: {
                  slidesPerView: 1.2,
                  spaceBetween: 25,
                  centeredSlides: false,
                },
                768: {
                  slidesPerView: 1.2,
                  spaceBetween: 25,
                },
                1024: {
                  slidesPerView: 2.1,
                  spaceBetween: 200,
                },
            },
        });

    
    }
}


function initTeamSlider(){
    if( $('.swiper-team').length ){
        var settingsFade = {
            slidesPerView: 1.2,
            spaceBetween: 25,
            speed: 1000,
            effect: 'fade',
            noSwipingClass: 'swiper-slide',
            navigation: {
                nextEl: ".swiper-team .swiper-button-next",
                prevEl: ".swiper-team .swiper-button-prev",
            },
            pagination: {
              el: ".swiper-team-pagination",
              clickable: true,
              renderBullet: function (index, className) {
                var name = $('.swiper-team .swiper-slide')
				        .eq(index)
                            .attr('data-name'),
                    position = $('.swiper-team .swiper-slide')
                        .eq(index)
                            .attr('data-position'),
                    bullet = 
                        '<div class="' + className + ' ">'  + 
                        '<h6 class="name">'+ name + '</h6>' +
                        '<span class="position">'+ position + '</span>' +
                        '</div>';

                return bullet;
              },
            },
            breakpoints: {
                768: {
                  slidesPerView: 1,
                  spaceBetween: 0,
                },
            },
        };
        var settingsSlide = {
            slidesPerView: 1.2,
            spaceBetween: 25,
            // speed: 1000,
            effect: 'slide',
            pagination: {
              el: ".swiper-pagination",
              clickable: true,
            },
            breakpoints: {
                200: {
                  slidesPerView: 1.2,
                  spaceBetween: 25,
                  centeredSlides: false,
                  effect: 'slide',
                  loop: true,
                },
                768: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                },
            },
        };

        var swiperTeam;
        if ($(window).width() < 768 ){
            swiperTeam = new Swiper(".swiper-team", settingsSlide);
        } else {
            swiperTeam = new Swiper(".swiper-team", settingsFade);
        }

        $(window).resize(function(){
            swiperTeam.destroy(true,true);
            if ($(window).width() <= 768 ){
                swiperTeam = new Swiper('.swiper-team',settingsSlide);
                // swiperAboutTimeline.params.effect = 'slide'; 
            }
            else {
                swiperTeam = new Swiper('.swiper-team', settingsFade);
            }
            swiperTeam.update();
            swiperTeam.updateSlides();

        });
    }
}

function initHomeMap( mapData ){
    if( $('.geo-block .map').length ){
        var existingPartnership = mapData.existing_partnership;
        var productRegistration = mapData.product_registration;
         
        function createMapPopup( countries, color ){
            $.each( countries, function( key, val ) {

                var titleTranslated = $( '[data-country='+ val.country.value+ ']'  );
                var mapSvg  = $( '#' + val.country.value ),
                    html    = `<div class="inside">
                              '<h6 class="title" style="color:${color}">${titleTranslated.eq(0).text()}</h6>`;
                    $.each( val.parameters, function( key, p_val ) {
                        if( p_val.url != '' ){
                            html += `<a href="${p_val.url}" target="_blank" rel="noopener noreferrer">
                                        <span class="country" style="color:${/* color */''}">${p_val.partner_name}</span>
                                    </a>
                                    <div class="description">${p_val.description}</div>`;
                        } else {
                            html += `<span class="country" style="color:${/* color */''}">${p_val.partner_name}</span>
                            <div class="description">${p_val.description}</div>`;
                        }
                    });

                    html  += `</div>`;

                    mapSvg.addClass('active')
                    .powerTip(
                        {
                            smartPlacement:true,
                            placement: 'w',
                            popupClass: 'map-popup',
                            mouseOnToPopup: true,
                        }
                    ).data('powertip', html ).css({ fill: color });

                return mapSvg;
            });
        }

        var existingPartnerColor = $('.map-tabs .box-title').eq(1).css("color");
        var productRegColor = $('.map-tabs .box-title').eq(2).css("color");

        createMapPopup(productRegistration, productRegColor);
        createMapPopup(existingPartnership,existingPartnerColor);

        mainJS.initSubitemDropdownBox( $('.geo-block' ) );
        mainJS.initDropdownBox( $('.geo-block' ) );
    }
}


function initRunningNumbers(){

    if( $('.numbers-block').length ){
        var numbers = $('.numbers-block .number');
        
        $('.numbers-block').waypoint(function(direction) {
            if (direction == "down"){
                numbers.each(function (i) {
                    var text    = $(this).parent().find( 'p' ),
                        number  = $(this);
                    TweenMax.to( number.parent() , 0.3, { 
                        ease:Linear.easeOut, 
                        delay: i*0.2, 
                        top:0,
                        opacity:1,
                        onStart: function() {
                            fadeInTextScale( text );
                            runningNumbers( number );
                        }
                    });
                });
                this.destroy();
            }
        },{
            offset: "50%"
        });
        

        function fadeInTextScale( text ){

            TweenMax.staggerFromTo( text, 0, { autoAlpha:1, }, { autoAlpha:1, }, 1, startAnimation );
            
            function startAnimation() {
                TweenMax.staggerFromTo( text.find("span") , 0.2, { autoAlpha: 0, }, {
                    autoAlpha: 1,
                }, 0.02 );
            }
        }

        function runningNumbers( el ){
            var $this = el,
            countTo = $this.attr("data-num");
            $({ countNum: $this.text() })
                .animate({countNum: countTo },{
                    duration: 850,
                    easing: "swing",
                    step: function () {
                        $this.text(
                            Math.ceil(this.countNum).toLocaleString("en").replace(",", " ").replace(",", " ")
                        );
                    },
                    complete: function () {
                        $this.text(
                            Math.ceil(this.countNum).toLocaleString("en").replace(",", " ").replace(",", " ")
                        );
                    }
                }
            );
        }
    }  
}


function initLottie(){

    if(typeof lottieHeroHome != 'undefined'){
        var paramsHeroHome = {
            container: document.querySelector('.lottie-home-hero'),
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: lottieHeroHome
        }
        lottie.loadAnimation(paramsHeroHome)

    }

    if(typeof lottieHeroWhite != 'undefined'){
        var paramsHeroWhite = {
            container: document.querySelector('.lottie-home-white'),
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: lottieHeroWhite
        }
        lottie.loadAnimation(paramsHeroWhite)
    }

    if(typeof lottieHeroOrange != 'undefined'){
        var paramsHeroOrange = {
            container: document.querySelector('.lottie-home-orange'),
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: lottieHeroOrange
        }

        lottie.loadAnimation(paramsHeroOrange)
    }

    if(typeof lottieHeroOrange != 'undefined'){
        var teamBottom = {
            container: document.querySelector('.lottie-team-bottom'),
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: teamBottom
        }

        lottie.loadAnimation(teamBottom)
    }

    if(typeof teamTop != 'undefined'){
        var paramsTeamTop = {
            container: document.querySelector('.lottie-team-top'),
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: teamTop
        }

        lottie.loadAnimation(paramsTeamTop)
    }
}


$(document).ready(function(){
    initTimelineSlider();
    initTeamSlider();
    initHomeMap(mapData);
    initRunningNumbers();
    initLottie();
});